import * as React from 'react';
import _ from 'lodash';
import { useState } from 'react';
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { BaseLayout, FlexColumn, FlexRow, IconButton, SubSection, SubSections, UncontrolledCarousel } from '../components';
import homeData from '../data/home.json';
import { Link } from 'gatsby';
import classnames from 'classnames';
import CdnImage from '../components/CdnImage/CdnImage';

export const HomePageInternal = (home: typeof homeData) => {
  const [ userId, setUserId ] = useState<string>('');
  const bannerItems = _.map(home.banners, ({ src, altText, link }) => {
    return {
      src: src,
      altText: altText,
      caption: '',
      link: link,
    };
  });

  return (
    <BaseLayout title="Home">
      <div className="home-page header-padding">
        <div className="home-carousel">
          <UncontrolledCarousel indicators controls className="w-100" items={bannerItems} />
          <div className="login-container d-none d-xl-flex justify-content-end">
            <div>
              <Card className="login-card">
                <CardBody>
                  <h6>Home Banking</h6>
                  <Form action={`https://bsdc.onlinecu.com/racine/#/?userid=${userId.trim()}`}>
                    <FormGroup>
                      <Label>User Id:</Label>
                      <Input onChange={(e) => setUserId(e.target.value)} />
                    </FormGroup>

                    <Button block type="submit">Login</Button>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>

        <SubSections>
          {[
            <SubSection key="best-rates" anchor="best-rates" headerLabel="Best Rates">
              <Row className="text-center">
                {
                  _.map(home.bestRates, ({ label, subText, rates }, i) => {
                    return (
                      <Col className="best-rate" tag={FlexColumn} justifyAround alignCenter key={i}>
                        <div className="label">{label}</div>
                        <p className="subtext">{subText}</p>
                        <FlexRow alignCenter>
                          {
                            _.map(rates, (rate, i) => {
                              const rateFontSize = 4.5 / rates.length;
                              const sideFontSize = 1.25 / rates.length;
                              const isLast = rates.length - 1 === i;
                              return (
                                <FlexRow className={classnames({ 'rate-border': !isLast })} alignCenter>
                                  <div>
                                    <strong className="rate" style={{ fontSize: `${rateFontSize}rem`, lineHeight: `${rateFontSize}rem` }}>{rate.rate}</strong>
                                  </div>
                                  <FlexColumn>
                                    <span className="flex side" style={{ fontSize: `${sideFontSize}rem` }}>{rate.super}</span>
                                    <span className="flex side" style={{ fontSize: `${sideFontSize}rem` }}>{rate.sub}</span>
                                  </FlexColumn>
                                </FlexRow>
                              );
                            })
                          }
                        </FlexRow>
                      </Col>
                    );
                  })
                }
              </Row>
              <Row className="mt-5 text-center">
                <Col md={6}>
                  <Button tag={Link} to="/borrow/#loan-rates" className="mb-2 mb-md-0" color="primary">View All Loan Rates</Button>
                </Col>
                <Col md={6}>
                  <Button tag={Link} to="/save/#savings-rates" color="primary">View All Savings Rates</Button>
                </Col>
              </Row>

              <Row className="d-flex flex-row mt-5 text-primary text-center">
                <Col className="d-flex">
                  <IconButton to="https://bsdcapi.onlinecu.com/racine/LoanApplications/racineDLA.html?docid=1" icon="fa-car">
                    Apply for a Vehicle Loan
                  </IconButton>
                </Col>
                <Col className="d-flex">
                  <IconButton to="https://bsdcapi.onlinecu.com/racine/LoanApplications/racineDLA.html?docid=1" icon="fa-home">
                    Apply for a Mortgage
                  </IconButton>
                </Col>
                <Col className="d-flex">
                  <IconButton file to="/pdfs/credit-card-application.pdf" icon="fa-credit-card">
                    Apply for a Credit Card
                  </IconButton>
                </Col>
                <Col className="d-flex">
                  <IconButton to="/borrow" icon="fa-info-circle">
                    Get More Info
                  </IconButton>
                </Col>
              </Row>

              <Row className="d-flex flex-row mt-5 text-center">
                <Col>
                  <CdnImage className="mb-4 img-fluid" src={home.infoBoxes.memberResources.imageSrc} alt="Member Resources"/>
                  <Link className="text-primary" to="/member-resource-center"><h6>Member Resources</h6></Link>
                  <p>{home.infoBoxes.memberResources.text}</p>
                </Col>
                <Col>
                  <CdnImage className="mb-4 img-fluid" src={home.infoBoxes.theCuForMe.imageSrc} alt="The CU for ME Difference"/>
                  <Link className="text-primary" to="/mission/#cu-for-me"><h6><em>The CU for</em> <strong>ME</strong> Difference</h6></Link>
                  <p>{home.infoBoxes.theCuForMe.text}</p>
                </Col>
                <Col>
                  <CdnImage className="mb-4 img-fluid" src={home.infoBoxes.newsAndInformation.imageSrc} alt="News and Information"/>
                  <Link className="text-primary" to="/news-info"><h6>News and Information</h6></Link>
                  <p>{home.infoBoxes.newsAndInformation.text}</p>
                </Col>
              </Row>
            </SubSection>,
          ]}
        </SubSections>
      </div>
    </BaseLayout>
  );
};

export default () => <HomePageInternal {...homeData} />;
